// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

 

circle{
  background-color : "red";
  color : "red"

}

#stepper_flight_map{
  background-color : transparent;
 
}

.MuiSvgIcon-root path{
  background-color : red;
}

#stepper_flight_map p {
 color:white;
}

#stepper_flight_map .MuiStepConnector-line{
  border-color : white;
}

.rsm-line {
  color : transparent
}


div.flight_map h3{
  color : white
}

div.flight_map h5{
  font-size : 1.3em
}

section .card.secondary.flight_map  {
  background-color : #0f1525;
}

.rsm-geography:active{
  fill :#043d58;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,4CAA4C;EAC5C,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;;;AAIA;EACE,wBAAwB;EACxB;;AAEF;;AAEA;EACE,8BAA8B;;AAEhC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;CACC,WAAW;AACZ;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE;AACF;;;AAGA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".App {\r\n  text-align: center;\r\n}\r\n\r\n.App-logo {\r\n  animation: App-logo-spin infinite 20s linear;\r\n  height: 40vmin;\r\n  pointer-events: none;\r\n}\r\n\r\n.App-header {\r\n  background-color: #282c34;\r\n  min-height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  font-size: calc(10px + 2vmin);\r\n  color: white;\r\n}\r\n\r\n.App-link {\r\n  color: #61dafb;\r\n}\r\n\r\n \r\n\r\ncircle{\r\n  background-color : \"red\";\r\n  color : \"red\"\r\n\r\n}\r\n\r\n#stepper_flight_map{\r\n  background-color : transparent;\r\n \r\n}\r\n\r\n.MuiSvgIcon-root path{\r\n  background-color : red;\r\n}\r\n\r\n#stepper_flight_map p {\r\n color:white;\r\n}\r\n\r\n#stepper_flight_map .MuiStepConnector-line{\r\n  border-color : white;\r\n}\r\n\r\n.rsm-line {\r\n  color : transparent\r\n}\r\n\r\n\r\ndiv.flight_map h3{\r\n  color : white\r\n}\r\n\r\ndiv.flight_map h5{\r\n  font-size : 1.3em\r\n}\r\n\r\nsection .card.secondary.flight_map  {\r\n  background-color : #0f1525;\r\n}\r\n\r\n.rsm-geography:active{\r\n  fill :#043d58;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
