import React, {useState, useEffect} from "react";
import Routes from "./Routes.js" 
import {config} from "./config.js";


const Unsubscribe = (props) => {

  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  let {pathname, search} = props.location

  pathname = pathname ? pathname.replace("/","").toLowerCase() : ""
  search = search ? search.replace("?", "") : ""
  
  let params = null
  try {
    params = JSON.parse('{"' + decodeURI(search.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}')
  } catch (error) {
    params = null
  }

  useEffect(() => {
    
    if(pathname === "unsubscribe"){

      Routes.get("/unsubscribe", params)
      .then((response) => {
        setSuccess(response.success)
        setMessage(response.message)
      })
      .catch(error =>  setMessage(error.toString()))

    }else if(pathname === "unsubscribeall"){

      Routes.get("/unsubscribeAll", params)
      .then((response) => {
       setSuccess(response.success)
       setMessage(response.message)
  
      })
      .catch(error =>  setMessage(error.toString()))
    }

    setTimeout(() => {
      window.location.replace(config.front)         
    }, 5000);
  },[]);


  return (
    <section style={{background : "white"}}>
      <div class="card secondary" style={{textAlign : "center"}}>
        
        {success ?

          <p style={{color : "green"}}>
            {message ? message : ""}
          </p>

        :

        <p style={{color : "red"}}>
          {message ? message : ""}
        </p>

        }
        
       
      </div>
    </section>
  );
};

export default Unsubscribe;
