let default_state = {
    awbInfo : null, 
    flightInfo : null,
    statusInfo : [],   
    stations : [], 
    flights : [],
    routes : [],
    carriers : [],
    prefix : null,
    serial : null,
    email : null,
    noAWBFound : false,
    displayStatusHistory : false  
}
export default {

    reducer_main : function (state = default_state, action) {

        if(action.reset){
          state = {...default_state, prefix : state.prefix, serial : state.serial}
        }
        
        if(action.displayStatusHistory !== undefined)
         state.displayStatusHistory = action.displayStatusHistory

        if(action.noAWBFound !== undefined)
            state.noAWBFound = action.noAWBFound


        if(action.prefix !== undefined)
            state.prefix = action.prefix

        if(action.serial !== undefined)
            state.serial = action.serial
        
        if(action.email !== undefined)
            state.email = action.email

        if(action.flightInfo !== undefined)
          state.flightInfo = action.flightInfo
      
        if(action.awbInfo !== undefined)
          state.awbInfo = action.awbInfo
      
        if(action.stations !== undefined)
          state.stations = action.stations  
      
        if(action.flights !== undefined)
          state.flights = action.flights  
        
        if(action.statusInfo !== undefined)
          state.statusInfo = action.statusInfo  
        
        if(action.routes !== undefined)
          state.routes = action.routes  
      
        return state
      }
}