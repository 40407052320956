//import moment from 'moment-timezone';
//const utc_central_europe = moment().tz( "Europe/Paris").format("Z").substring(1,3)
const img_path = "./img"

var config = {

  //utc_central_europe : utc_central_europe,

  MONTHS : ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],

  ICONS : {
      dlv :<img src={require(`${img_path}/delivered_white.png`)} alt="Delivred" />  ,
      dep : <img src={require(`${img_path}/aeroplane_white.png`)} alt ="Aeroplane" /> ,
      rcf : <img src={require(`${img_path}/received_white.png`)} alt="Received" />,
      rcs : <img src={require(`${img_path}/received_white.png`)} alt="Received" />,
      nfd : <img src={require(`${img_path}/mail_white.png`)} alt ="Mail" /> ,
      dep_truck : <img src={require(`${img_path}/truck_white.png`)} alt ="Truck" />,
      plane : <img src={require(`${img_path}/plane.png`)} alt ="Plane" /> ,
      arrival : <img src={require(`${img_path}/arrival.png`)} alt ="arrival" /> ,
      take_off : <img src={require(`${img_path}/take-off.png`)} alt ="take-off" /> ,


  },

  statusNames : {
      "RCF" : "Received from flight",
      "RCS" : "Received from shipper",
      "DEP" : "Departed",
      "DLV" : "Delivered",
      "NFD" : "Notified consignee",
      "DEP_TRUCK" : "Departed on truck",
      "MAN" : "Manifested"
  },

  convertDate : function (dateString, getTime = true, utc = true, flight = false){

      let date = "";
      let day = "";
      let month = "";
      let hour = "";
      let minute = "";
      let time = "";
    
      date = new Date(dateString.substring(0,16));
    
      //if(utc) date.setHours(date.getHours() + parseInt(utc_central_europe))
    
      day = date.getDate();
    
      day = ('0' + day).slice(-2);
    
      month = this.MONTHS[date.getMonth()];
    
      hour = date.getHours();
      hour = ('0' + hour).slice(-2);
    
      minute =  date.getMinutes() ;
      minute =  ('0' + minute).slice(-2)  ;
    
      time = day + "" + month  
    
      if(flight)
        time = day + month;
    
      if(getTime)
        time = time + " | " + hour + ":" + minute;
    
      return time;  
    
  },


  convertDate2 : function (dateString, getDateTime = false){

    dateString = dateString ? dateString.toString() : ""
    let month = dateString ?  dateString.substring(5,7) : "" ;
    let day = dateString ?  dateString.substring(8,10) : "" ;
    let hour = dateString ?  dateString.substring(11,13) : "" ;
    let minute = dateString ?  dateString.substring(14,16) : "" ;

    //console.log("#freeM", hour, minute)


    if(month){
      month = parseInt(month)
      month = this.MONTHS[month - 1];
    }

    
    let date = day + month 

    if(getDateTime)
      date = date + " | " + hour + ":" + minute;

  
    return date
  
  },

  convertTime : function (time){

    time = time ? time.toString() : ""
    let hour = ""
    let minute = ""

    if(time){
      hour =  time.slice(0,2) 
      minute = time.slice(2,4)
    }

    return hour + ":" + minute 
   
  }


}

export default config