import React from 'react';
import ReactDOM from 'react-dom';
import './uptown.css';
import Main from './Main';
import Unsubscribe from './Unsubscribe';
import * as serviceWorker from './serviceWorker';
import {Route, BrowserRouter} from 'react-router-dom';

ReactDOM.render(

   <BrowserRouter>
     
      <div>
        <Route path="/:id" component={Main}></Route>
      </div>
      <div>
        <Route exact path="/" component={Main}></Route>
      </div>

      <div>
        <Route  path="/Unsubscribe" component={Unsubscribe}></Route>
      </div>

      <div>
        <Route  path="/UnsubscribeAll" component={Unsubscribe}></Route>
      </div>


   </BrowserRouter >
   
//
	, document.getElementById('root')

	);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();




