import axios from 'axios';
//import { Buffer } from "buffer";
import config from './config.js'


const configs = config.config

const endpoint =  configs.host

const routes = {

    get : async function(route, params){
        let url = endpoint + route
        //let headers = { 'Authorization': `Bearer ${localStorage.getItem("appToken")}`}

        return await axios.get(url,
          { params : params, headers : {}}, 
          ).then( response => {
          return response.data ? response.data : null
        }).catch( (error)=>{
          console.log('freeError', error)
          if(error.response && error.response.data){
            let e =  error.response.data
            if(e.error)
              return e
            else
              return {error : true, statusCode : error.response.status, message : error.response.data}
          }else{
            return {error : true, message : "Internal Server Error : " + error.toString()}
          }
        }); 
    },

    /*
    post : async function (route){
      let url = endpoint + route
      return await axios({
        method: 'post',
        url: url ,
        withCredentials : false,
        data: {appSecret : Buffer.from(configs.pathfinder_seceret)}
    }).then((response)=>{
        return response.data ? response.data : null
      }).catch((error)=>{
        console.log('error',error.toString())
        if(error.response && error.response.data){
          let e =  error.response.data
          if(e.error)
            return e
          else
            return {error : true, statusCode : error.response.status, message : error.response.data}
        }else{
          return {error : true, message : "Internal Server Error : " + error.toString()}
        }      
      })
    
    }*/
}
 
export default routes
