
const REACT_APP_ENV = process.env.REACT_APP_ENV
const REACT_APP_FRONT = process.env.REACT_APP_FRONT
const REACT_APP_BACK = process.env.REACT_APP_BACK
const PATHFINDER_SECRET = process.env.REACT_APP_PATHFINDER_SECRET
const img_path = "./img"

let  FRONT_HOST = "http://localhost:3000"
let  BACK_HOST = "http://localhost:8080"

if(REACT_APP_ENV !== "LOCAL"){
	FRONT_HOST = REACT_APP_FRONT
	BACK_HOST =  REACT_APP_BACK
}

exports.config = {
	env : REACT_APP_ENV,
	front  : FRONT_HOST ,
	host   : BACK_HOST,
	pathfinder_seceret : PATHFINDER_SECRET ? PATHFINDER_SECRET : "NO_SECRET",
	logo :  require(`${img_path}/logo.png`),
	majorColor : "#002943"
}